import { Box, Container, Typography } from "@mui/material";
import drawerIcon from '../../../../assets/drawerArrow.png';
import { useDispatch } from "react-redux";
import './style.css'

function CustomSubHeader(props) {

    const { backToRoute, title = 'title', isCalendar = false, backArrowActive = false, handleCalendar = () => {} } = props
    const dispatch = useDispatch()

    const handleCalendarBtn = () => {
        handleCalendar()
    }

    return (
        <>
            <Container
                disableGutters
                sx={{
                    background: "#3D7CC9",
                    p: ["1px .8rem !important"],
                    m: 0,
                    position: "sticky",
                    zIndex: 3,
                    top: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: "5px",
                    lineHeight: "1",
                    minHeight: "60px",
                    borderRadius: "0px",
                    width: "100%",
                    minWidth: "100%"
                }}
            >
                {backArrowActive ?
                    <Box onClick={() => { backToRoute() }} sx={{ display: ["block", "block", "none"], cursor: "pointer" }}>
                        <img src="https://img.pristyncare.com/new_brand/elements/attendance/back-arrow.svg" alt='back arrow' />
                    </Box>
                    : ``
                }
                <img className="drawer-icon" src={drawerIcon} onClick={() => dispatch({ type: true })} />
                <Typography
                    align="left"
                    variant="h2"
                    fontWeight="600"
                    color="#fff"
                    sx={{
                        fontSize: ["18px !important", "18px", "clamp(15px, 5vw, 22px)"],
                    }}
                    className="historyListHeading"
                >
                    {title}
                </Typography>
                {isCalendar ? <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleCalendarBtn}>
                    <div className="group-info-btn-container" style={{
                        height: "40px",
                        width: "40px",
                        background: "transparent",
                        borderRadius: "9px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        cursor: 'pointer'
                    }}>
                        <img src="https://img.pristyncare.com/new_brand/elements/attendance/back-arrow.svg" alt='back arrow' />
                    </div>
                </Box>
                    : ""
                }


            </Container>
        </>
    )
}

export default CustomSubHeader;