
export const getInitialState = (stateName) => {
  const allInitialStates = {
    authentication: {
      loggedIn: false,
      loggingIn: true,
      showRegisterUser: false,
      user: {
        name: "",
        roles: {
          role: "",
        },
      },
      error: {},
      isOtpLoader: false,
      topText: "Welcome Back",
      isOtpScreen: false,
      isVerifyLoader: false,
      response: {
        status: "",
      },
      rolesListResponse: {
        result: {
          roles: { hospital: [] },
        },
      },
      rolesListRequesting: false,
      rolesListActive: false,
      rolesListFailed: false,
    },
    users: {
      authentication: {
        user: {
          username: "",
          userId: "",
          roles: {
            role: "",
          },
        },
      },
      notificationId: "",
    },
    alert: {},
    hospitals: {
      isFetchingHospitalList: false,
      isHospitalActive: false,
      hospitalList: [
        {
          hospital_id: "",
          sid: "",
          friendly_name: "",
        },
      ],
      currentHospitalData: { hospital_city: "" },
      isFetchingLastIndexAPIError: false,
      isFetchingLastIndexAPIActive: false,
      isFetchingLastIndexAPI: false,
      lastIndexAPIResponse: {},
      isDrawer:{patient:false,hospital:false}
    },
    patients: {
      patients: {
        pendingPatient: [],
        activePatient: [],
      },
      isFetchingPatientList: false,
      patientListActive: false,
      patientInfoAPIData: {
        result: [
          {
            key: "",
            label: "",
            value: "",
          },
        ],
      },
      patientInfo: {
        allInfo: {},
      },
      mopArr: [],
      endOfChatArr: [],
      selectedMop: [],
    },
    chatBox: {
      isFetchingPatientChatMessages: false,
      patientChatMessagesActive: false,
      fetchingPatientChatMessagesError: "",
      isFetchingGeneralChatMessages: false,
      GeneralChatMessagesActive: false,
      hasMoreMessages: true,
      fetchingGeneralChatMessagesError: "",
      patientMessages: [],
      selectedMessage: {},
      chatTemplates: [],
      generalChatMessages: [],
      blockChatBoxScreen:false,
      privateChatMessagesActive: false,
      privateChatMessages:[],
      fetchingPrivateChatMessagesError:""
    },
    documentsPopup: {
      documents: [],
      isFetchingDocumentList: false,
      documentsListActive: false,
      userSanityPopup: false,
      showPrivateChatPopup: true
    },
    groupInfoPopup: {
      groupInfo: [],
      isFetchingGroupInfoList: false,
      isGroupInfoListActive: false,
      isAddGroupMemberRequesting: false,
      groupMemberAddRequestSuccess: false,
      addMemberResponse: {},
      isRemoveGroupMemberRequesting: false,
      groupMemberRemoveRequestSuccess: false,
      removeMemberResponse: {},
    },
    config: {
      versionConfigData: {
        result: [
          {
            surgeryApprovers: [],
          },
        ],
      },
      commonData: {},
      showSpinningLoader: false,
      documentList: {
        data: [],
      },
    },
    homeState: {
      showChatBox: false,
      showGeneralChatMessages: false,
      showGroupInfoPopUp: false,
      showDocumentsPopup: false,
      showAcceptPopup: false,
      showRejectPopup: false,
      showHospitalList: false,
      mainTitle: "Hospital Chat",
      checkUpdatedBuild: true,
      logoutAnchorEL: null,
      noHospitalsFound: false,
      loadingSpinnerText: "This is taking a little bit longer !",
      showAlarmNotification: false,
      alarmNotifications: [],
      alarmSound: false,
      notificationState: {},
      selectedCities: [],
      uploadDocumentStatus: false,
      activeTab: 'home',
      screenName:{hospitalList:true,patientList:false,chatBox:false},
    },
    cacheStorage: {
      hospitalListCache:{},
      patientListsCache: {},
      chatBoxMessagesCache: {},
      sendAgainList: [],
      retryList: [],
      checklistHospitalScreenCache: {},
      checklistPatientScreenCache: {},
      checklistHospitalPatientScreenCache: {},
      pocListCache: [],
      statesWithTTL:[],
      chatBoxGeneralChatMessagesCache:{},
      privateChatMessagesCache:{},
      sendAgainListCount:null
    },
    socketConnectivity: {
      socketConfig: {
        socketData: {},
        isConnectingSocket: false,
        isConnectionSocketError: false,
        socketConnect:false,
      },
      socketMessage: {},
      socketSurgery: [],
    },
    checklistSummary: {
      screenName: "hospitalScreen",
      activeTab:'active',
      hospitalFilter: {
        startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
        searchTextHospital: "",
        city: [],
        search: ''
      },
      patientFilter: {
        startDate: '',
        endDate: '',
        searchTextPatient: "",
        hospital: '',
        search: '',
        mop: [],
        pds: [],
        bbs: [],
        filterStatus: '',

      },
      hospitalViewFilter:{
        startDate: '',
        endDate:'',
        searchTextHospitalView:'',
        mop:[]
      }
    },
    pcScore:{},
    activityState:{
      attendanceList:[],
      todayEventHistoryList:[],
      dateRangeEventHistoryList:[]
    }
  };

  return allInitialStates?.[stateName] || {};
};

export const resetSelectedStatesToInitial = (
  dispatch,
  statesData = [{ stateName: "", action: "", addtionalStateData: {} }]
) => {
  statesData.forEach(
    ({ stateName = "", action = "", addtionalStateData = {} }) => {
      dispatch(
        action({
          ...getInitialState(stateName),
          ...addtionalStateData,
        })
      );
    }
  );
};
