export const activityConstants = {
  GET_ATTENDANCE_REQUESTING: "GET_ATTENDANCE_REQUESTING",
  GET_ATTENDANCE_FETCHED: "GET_ATTENDANCE_FETCHED",
  GET_ATTENDANCE_FAILED: "GET_ATTENDANCE_FAILED",
  GET_TODAY_ATTENDANCE_EVENT_REQUESTING: "GET_TODAY_ATTENDANCE_EVENT_REQUESTING",
  GET_TODAY_ATTENDANCE_EVENT_FETCHED: "GET_TODAY_ATTENDANCE_EVENT_FETCHED",
  GET_TODAY_ATTENDANCE_EVENT_FAILED: "GET_TODAY_ATTENDANCE_EVENT_FAILED",
  
  GET_DATE_RANGE_ATTENDANCE_EVENT_REQUESTING: "GET_DATE_RANGE_ATTENDANCE_EVENT_REQUESTING",
  GET_DATE_RANGE_ATTENDANCE_EVENT_FETCHED: "GET_DATE_RANGE_ATTENDANCE_EVENT_FETCHED",
  GET_DATE_RANGE_ATTENDANCE_EVENT_FAILED: "GET_DATE_RANGE_ATTENDANCE_EVENT_FAILED",
};
