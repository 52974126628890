import { Box, Chip, Container, CssBaseline, Divider, List, ListItem, ListItemText, Typography, Stack, styled } from "@mui/material";
import "./style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSubHeader from "../customSubHeader";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { getActivitiesActions } from "../../../../_actions";
import { convertUTCToISTUtility, eventBucketLogoUrl } from "../../../../_helpers";
import { activityConstants } from "../../../../_constants";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => {
    return {
        height: 10,
        background: "#fff",
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: (value > 50) ? '#3AE054'
                : value > 25 && value < 50 ? '#FFD917'
                    : value > 10 && value < 25 ? '#FE6A2E'
                        : '#FA2333',
            ...theme.applyStyles('white', {
                backgroundColor: '#fff',
            }),
        },
    }
});

function CheckInHistoryByID() {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const todayEventHistoryList = useSelector((state) => state.getAttendanceData?.todayEventHistoryList);
    const [backArrowEnabled, seBackArrowEnabled] = useState(location.search.includes('check=true'))
    const role = useSelector(state => state.authentication.user.roles) || ''
    const isGeoLocation = role?.otherRoles && role?.otherRoles?.find((location) => (location === 'Geo Location Enabled'))

    const [selectedDate, setSelectedDate] = useState(params.checkInId)
    function convertUTCToIST(javaLocalDateTime, dateFormatReturn = false) {
        if (dateFormatReturn) {
            return convertUTCToISTUtility(javaLocalDateTime, dateFormatReturn)
        } else {
            return convertUTCToISTUtility(javaLocalDateTime, dateFormatReturn)
        }
    }

    function formatDate(date) {
        // Get the day, month (in short format), and year
        const day = date.getDate(); // Get the day of the month (no leading zero)
        const month = date.toLocaleString('default', { month: 'short' }); // Short month abbreviation
        const year = String(date.getFullYear()).slice(-2); // Last 2 digits of the year

        return `${day} ${month}, ${year}`;
    }

    function convertUTCToISTDate() {

        if (!(todayEventHistoryList && todayEventHistoryList[0]?.eventTime && Array.isArray(todayEventHistoryList) && todayEventHistoryList.length)) {
            return formatDate(new Date());
        }
        const javaLocalDateTime = todayEventHistoryList[0].eventTime;
        return convertUTCToIST(javaLocalDateTime, true);
    }


    const navigateToRoute = () => {
            navigate('/activity/history/')
    }

    useEffect(() => {
        dispatch({
            type: activityConstants.GET_TODAY_ATTENDANCE_EVENT_FETCHED,
            todayEventHistoryList: [],
        })
        dispatch(getActivitiesActions.getTodayEventList(selectedDate))
    }, [selectedDate])


    return (
        <>
            <CssBaseline />
            <CustomSubHeader title={`Login History - ${convertUTCToISTDate()}`} backArrowActive={backArrowEnabled} backToRoute={navigateToRoute} />
            <List sx={{ width: '100%', maxWidth: '100%', bgcolor: '#fff', display: 'flex', flexDirection: "column", gap: "12px", padding: '15px' }}>
                {
                    todayEventHistoryList && Array.isArray(todayEventHistoryList) && todayEventHistoryList.length
                        ?
                        todayEventHistoryList.map((item) => {
                            return (
                                <>
                                    <ListItem>
                                        <ListItemText
                                            sx={{ textTransform: "capitalize" }}
                                            primary={
                                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#4B5563" }}>
                                                    {item?.hospitalName}
                                                    {/* {item?.hospitalAddress ? ' - ' + item?.hospitalAddress : ''} */}
                                                </Typography>
                                            }
                                            secondary={
                                                <Container sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px !important", marginTop: "15px" }}>
                                                    <Box sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        textTransform: 'capitalize',
                                                        color: '#7A7A7A'
                                                    }}>
                                                        <Typography component="span" sx={{
                                                            fontWeight: '500',
                                                            fontSize: '12px',
                                                        }}>
                                                            {convertUTCToIST(item?.eventTime)}
                                                        </Typography>
                                                        <Typography sx={{
                                                            fontWeight: '500',
                                                            fontSize: '12px',
                                                        }}>
                                                            |
                                                        </Typography>
                                                        {
                                                            item.battery ?
                                                                <Typography component="span" sx={{
                                                                    display: 'flex', alignItems: "center", gap: '.5rem', fontWeight: '500',
                                                                    fontSize: '12px',
                                                                }}>
                                                                    {item.battery}%

                                                                    <Stack spacing={2} sx={{ flexGrow: 1, position: 'relative' }}
                                                                    >
                                                                        <img src={eventBucketLogoUrl + 'battery-icon.svg'} alt='battery logo' />
                                                                        <BorderLinearProgress
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                width: '19px',
                                                                                border: '1px solid #ddd',
                                                                                borderRadius: 'unset',
                                                                                top: '-15px',
                                                                                left: '1px',

                                                                            }}
                                                                            variant="determinate" value={item?.battery} />
                                                                    </Stack>
                                                                </Typography>
                                                                : ``
                                                        }
                                                    </Box>
                                                    <Box>
                                                        <Chip
                                                            sx={{ color: item.event.toLowerCase().includes('checkin') ? "#21A150" : "#965EC7" }}
                                                            label={
                                                                <Typography sx={{ display: "flex", alignItems: "center", gap: '5px', fontSize: "11px" }} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                                        style={{ transform: item.event.toLowerCase().includes('checkin') ? 'unset' : 'rotate(270deg)' }}
                                                                    >
                                                                        <g clipPath="url(#clip0_3183_1208)">
                                                                            <path d="M9 0C4.03745 0 0 4.03745 0 9C0 13.9626 4.03745 18 9 18C13.9626 18 18 13.9626 18 9C18 4.03745 13.9626 0 9 0Z"
                                                                                fill={item.event.toLowerCase().includes('checkin') ? "#22C55E" : "#965EC7"}
                                                                            />
                                                                            <path d="M11.0476 10.381L6.82143 6.14286C6.72619 6.04762 6.61302 6 6.4819 6C6.35111 6 6.2381 6.04762 6.14286 6.14286C6.04762 6.2381 6 6.35127 6 6.48238C6 6.61317 6.04762 6.72619 6.14286 6.82143L10.381 11.0476H6.7619C6.62698 11.0476 6.51381 11.0932 6.42238 11.1843C6.33127 11.2757 6.28571 11.3889 6.28571 11.5238C6.28571 11.6587 6.33127 11.7719 6.42238 11.8633C6.51381 11.9544 6.62698 12 6.7619 12H11.5238C11.6587 12 11.7717 11.9544 11.8629 11.8633C11.9543 11.7719 12 11.6587 12 11.5238V6.76191C12 6.62698 11.9543 6.51397 11.8629 6.42286C11.7717 6.33143 11.6587 6.28571 11.5238 6.28571C11.3889 6.28571 11.2759 6.33143 11.1848 6.42286C11.0933 6.51397 11.0476 6.62698 11.0476 6.76191V10.381Z"
                                                                                fill="#F9FAFB" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_3183_1208">
                                                                                <rect width="18" height="18" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                    {item?.event} {item.type ? item.type.toLowerCase().includes('manual') ? '(M)' : '(A)' : ''}
                                                                </Typography>
                                                            } >
                                                        </Chip>
                                                    </Box>

                                                </Container>
                                            }
                                        >
                                        </ListItemText >
                                    </ListItem >
                                    <Divider sx={{ margin: "0px !important" }} variant="inset" component="li" />
                                </>
                            )
                        })
                        :

                        <Box>
                            <Typography sx={{ height: '100%' }}>
                                <img src={eventBucketLogoUrl + 'event-empty.svg'} alt='event empty' />

                            </Typography>

                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    color: '#000',
                                    fontSize: '1.2rem',
                                    margin: '3rem 0'
                                }}
                            >
                                No event history found!
                            </Typography>
                        </Box>
                }
            </List>
        </>
    )
}

export default CheckInHistoryByID;