import { toast } from "react-toastify";
import { activityConstants } from "../_constants";
import { dateRangeEventHistoyService, getAttendanceListService, todayEventHistoyService } from "../_services";
import { configActions } from "./config.actions";
import { alertActions } from "./alert.actions";

function getAttendanceList(params) {
  return (dispatch, getState) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());
    getAttendanceListService(params).then(
      (res) => {
        dispatch(success(res));
        dispatch(configActions.hideSpinningLoader());
        // !!res?.description && toast.success(res.description)

      },
      (error) => {
        toast.error(error.description)
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
    dispatch(configActions.hideSpinningLoader());
  };

  function request() {
    return {
      type: activityConstants.GET_ATTENDANCE_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: activityConstants.GET_ATTENDANCE_FETCHED,
      attendanceList: res,
    };
  }
  function failure(error) {
    return { type: activityConstants.GET_ATTENDANCE_FAILED, error };
  }
}

function getTodayEventList(params) {
  return (dispatch) => {
    dispatch({ type: activityConstants.GET_TODAY_ATTENDANCE_EVENT_REQUESTING })
    dispatch(configActions.showSpinningLoader());

    todayEventHistoyService(params).then(
      (res) => {
        const { result } = res;
        dispatch({
          type: activityConstants.GET_TODAY_ATTENDANCE_EVENT_FETCHED,
          todayEventHistoryList: result || [],
        })
        dispatch(configActions.hideSpinningLoader());
      },
      (error) => {
        dispatch({ type: activityConstants.GET_TODAY_ATTENDANCE_EVENT_FAILED, error })
        dispatch(configActions.hideSpinningLoader());
      }
    )
  }
}

function getDateRangeEventList(params) {

  return (dispatch) => {
    dispatch({ type: activityConstants.GET_DATE_RANGE_ATTENDANCE_EVENT_REQUESTING })
    dispatch(configActions.showSpinningLoader());
    dateRangeEventHistoyService(params).then(
      (res) => {
        const { result } = res;
        dispatch({
          type: activityConstants.GET_DATE_RANGE_ATTENDANCE_EVENT_FETCHED,
          dateRangeEventHistoryList: result || [],
        })
        dispatch(configActions.hideSpinningLoader());
      },
      (error) => {
        dispatch({ type: activityConstants.GET_DATE_RANGE_ATTENDANCE_EVENT_FAILED, error })
        dispatch(configActions.hideSpinningLoader());
      }
    )
  }
}

export const getActivitiesActions = {
  getAttendanceList,
  getTodayEventList,
  getDateRangeEventList
};
