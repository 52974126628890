import { activityConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

export function getAttendanceData(
  state = getInitialState("activityState"),
  action
) {
  switch (action.type) {
    case activityConstants.GET_ATTENDANCE_REQUESTING:
      return {
        ...state,
      };
    case activityConstants.GET_ATTENDANCE_FETCHED:
      const { attendanceList } = action;
      return {
        ...state,
        attendanceList
      };
    case activityConstants.GET_ATTENDANCE_FAILED:
      return {
        ...state,
        attendanceFetchingError: action.error,
      };
    // Today Event Api data
    case activityConstants.GET_TODAY_ATTENDANCE_EVENT_REQUESTING:
      return {
        ...state,
      };
    case activityConstants.GET_TODAY_ATTENDANCE_EVENT_FETCHED:
      const { todayEventHistoryList } = action;
      return {
        ...state,
        todayEventHistoryList
      };
    case activityConstants.GET_TODAY_ATTENDANCE_EVENT_FAILED:
      return {
        ...state,
        todayEventHistoryFetchingError: action.error,
      };

    // Date Range Event Api data
    case activityConstants.GET_DATE_RANGE_ATTENDANCE_EVENT_REQUESTING:
      return {
        ...state,
      };
    case activityConstants.GET_DATE_RANGE_ATTENDANCE_EVENT_FETCHED:
      const { dateRangeEventHistoryList } = action;
      return {
        ...state,
        dateRangeEventHistoryList
      };
    case activityConstants.GET_DATE_RANGE_ATTENDANCE_EVENT_FAILED:
      return {
        ...state,
        todayEventHistoryFetchingError: action.error,
      };
    default:
      return state;
  }
}
