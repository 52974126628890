import React, { Fragment, useState } from "react";
import "./centralDaLetterModal.css";
import { CentralModal } from "../CentralModal/CentralModal";
import { CircularProgress, Divider } from "@mui/material";
import moment from "moment";
import { aws_bucket_url, downloadChatMessage } from "../../../_services";

export const CentralDaLetterModal = ({ daLetterData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmptyModal, setIsEmptyModal] = useState(false)
  const handleTimeStamp = (value) => {
    try {
      if (!moment(value).isValid()) {
        return '--'
      }
      return moment(value).format("DD MMM, YYYY");
    } catch (error) {
      return "--";
    }
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const viewDocument = (docUrl) => {
    if (docUrl === '') return;
    const docAwsBucketUrl = aws_bucket_url + docUrl;
    downloadChatMessage({ urls: [docAwsBucketUrl] }).then(res => {
      const { status = '', result = [] } = res;
      if (status === 'Success' && result.length > 0)
        window.open(result[0])
    })
      .catch(err => console.log('Signed url error', err))
  }

  const renderModalData = () => {
    return (
      <>
        {daLetterData.length > 0 && daLetterData.map((item, index) => {
          return (
            <>
              <Fragment key={`key-${index}`}>
                <div className="textReadOnlyRow"
                  style={{
                    'background': '#ccc',
                    'padding': '.5rem'
                  }}
                >
                  <span className="textReadOnlyLabel summaryModalLabel">{'DA Document-' + (+index + 1)}</span>
                  <span className="textReadOnlyValue"></span>
                </div>
              </Fragment>
              {
                Object.entries(item).map(([key, value]) => {
                  return (
                    <>

                      <Fragment key={`key-${key}`}>
                        <div className="textReadOnlyRow">
                          <span className="textReadOnlyLabel summaryModalLabel">{key}</span>
                          <span className="textReadOnlyValue">
                            {
                              key === 'date' ?
                                handleTimeStamp(value)
                                : key === 'daDocument'
                                  ? <span
                                    className="view-link"
                                    onClick={() => viewDocument(value)}
                                  >
                                    view
                                  </span>
                                  : value === ''
                                    ? '--'
                                    : value

                            }
                          </span>
                        </div>
                      </Fragment>
                    </>
                  )
                })
              }
              <Divider sx={{ mb: '2px' }} />
            </>
          )
        }
        )}
      </>
    )
  }

  return (
    <div className="textReadOnlyRow">
      <CentralModal
        open={isModalOpen}
        onClose={handleModalClose}
        modalTitle={"DA Letter Summary Data"}
        sx={{ minHeight: "155px" }}
        modalButton={
          <div className="defaultModalButton" onClick={handleModalOpen}>
            View
          </div>
        }
      >
        {isEmptyModal ? <div className="summaryModalLoader">
          <CircularProgress fontSize="small" />
        </div>
          :
          daLetterData.length === 0 ? <div className="noDataFoundText">No Data Found</div> :
            renderModalData()}
      </CentralModal>
    </div>
  );
};
