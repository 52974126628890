import { Photo, PictureAsPdf } from "@mui/icons-material";
import { homeStateActions } from "../_actions";
import { chatBoxService } from "../_services";

export class Debounce {
  debounceTimer;
  debounce(cb, delay = 1000) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(cb, delay);
  }
}

export const validateInput = (value, setState) => {
  // value = value.trim();

  const lengthError = value.length < 10;
  const regexError = Boolean(value.match(/\D/gm));

  setState(() => {
    return {
      error: lengthError || regexError,
      des: regexError
        ? "Only digits are allowed"
        : !value.length
          ? "Please enter the mobile number"
          : "Should contain only 10 digits",
    };
  });

  return value.length < 11;
};

export const EXTERNAL_HOSPITAL_ROLES = [
  "Receptionist",
  "Billing Professional",
  "OT Staff",
  "Nursing Staff",
  "Floor Staff",
  "RMO/CMO/Duty Doctor",
  "RMO/CMO",
  "Lab Staff",
  "Dietitian",
  "Insurance Hospital",
  "Hospital Doctor",
  "Duty Doctor",
  "Admin",
  "Owner",
  "Anaesthetist",
  "OT Technician",
  "TPA/Billing",
  "RMO"
];

export const roleConstants = {
  bd: {
    key: "bd",
    title: "BD Team",
  },

  categoryhead: {
    key: "categoryhead",
    title: "Category Heads",
  },

  doc: {
    key: "doc",
    title: "Doctor Team",
  },

  cb: {
    key: "cb",
    title: "Care Buddy Team",
  },

  cityops: {
    key: "cityops",
    title: "City Team",
  },

  ins: {
    key: "ins",
    title: "Insurance Team",
  },

  cx: {
    key: "cx",
    title: "Customer Experience Team",
  },

  finance: {
    key: "finance",
    title: "Finance Team",
  },

  externaluser: {
    key: "externaluser",
    title: "Hospital",
  },

  superadmin: {
    key: "superadmin",
    title: "Viewers",
  },

  centralmanager: {
    key: "centralmanager",
    title: "Central Ops Team",
  },

  centraldashboard: {
    key: "centraldashboard",
    title: "Central Ops Team",
  },

  centralteam: {
    key: "centralteam",
    title: "Central Ops Team",
  },

  "nursing staff": {
    key: "nursing staff",
    title: "Nursing Staff",
  },
};

export const getInitials = (name) => {
  const initial = String(name)?.trim().split(" ")[0][0].trim().toUpperCase();

  return initial;
};

export const allAppointmentSlots = [
  {
    label: "6:00 am - 7:00 am",
    startHour: "6",
    key: "6:00 - 7:00",
  },
  {
    label: "7:00 am - 8:00 am",
    startHour: "7",
    key: "7:00 - 8:00",
  },
  {
    label: "8:00 am - 9:00 am",
    startHour: "8",
    key: "8:00 - 9:00",
  },
  {
    label: "9:00 am - 10:00 am",
    startHour: "9",
    key: "9:00 - 10:00",
  },
  {
    label: "10:00 am - 11:00 am",
    startHour: "10",
    key: "10:00 - 11:00",
  },
  {
    label: "11:00 am - 12:00 pm",
    startHour: "11",
    key: "11:00 - 12:00",
  },
  {
    label: "12:00 pm - 1:00 pm",
    startHour: "12",
    key: "12:00 - 13:00",
  },
  {
    label: "1:00 pm - 2:00 pm",
    startHour: "13",
    key: "13:00 - 14:00",
  },
  {
    label: "2:00 pm - 3:00 pm",
    startHour: "14",
    key: "14:00 - 15:00",
  },
  {
    label: "3:00 pm - 4:00 pm",
    startHour: "15",
    key: "15:00 - 16:00",
  },
  {
    label: "4:00 pm - 5:00 pm",
    startHour: "16",
    key: "16:00 - 17:00",
  },
  {
    label: "5:00 pm - 6:00 pm",
    startHour: "17",
    key: "17:00 - 18:00",
  },
  {
    label: "6:00 pm - 7:00 pm",
    startHour: "18",
    key: "18:00 - 19:00",
  },
  {
    label: "7:00 pm - 8:00 pm",
    startHour: "19",
    key: "19:00 - 20:00",
  },
  {
    label: "8:00 pm - 9:00 pm",
    startHour: "20",
    key: "20:00 - 21:00",
  },
  {
    label: "9:00 pm - 10:00 pm",
    startHour: "21",
    key: "21:00 - 22:00",
  },
];

export const mainCacheConstants = {
  hospitalListCache: 'hospitalListCache',
  patientListsCache: "patientListsCache",
  chatBoxMessagesCache: "chatBoxMessagesCache",
  chatBoxGeneralChatMessagesCache: "chatBoxGeneralChatMessagesCache",
  privateChatMessagesCache:"privateChatMessagesCache",
  groupInfoListCache: "groupInfoListCache",
  checklistHospitalScreenCache: "checklistHospitalScreenCache",
  checklistPatientScreenCache: "checklistPatientScreenCache",
  checklistHospitalPatientScreenCache: "checklistHospitalPatientScreenCache",
  pocListCache: "pocListCache"
};

export const handleFileType = {
  "application/pdf": {
    text: "PDF Files",
    image: PictureAsPdf,
  },

  "image/jpeg": {
    text: "JPEG Files",
    image: Photo,
  },

  "image/png": {
    text: "PNG Files",
    image: Photo,
  },
};

export const clearLocalStorage = (keys = []) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};


export const updateMessagesBody = (messages) => {
  let arr = messages.map((item) => {
    let mention_data = item.Attributes.mention_data;
    if (mention_data && mention_data.length > 0) {
      for (let i = 0; i < mention_data.length; i++) {
        item.MessageBody = item.MessageBody.replace(
          mention_data[i]["name"],
          `<span style="text-decoration:underline;">${mention_data[i]["name"]}</span>`
        );
      }
    }
    return item;
  });
  return arr
}
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));



export const generateLocalId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const changeScreen = (dispatch, navigate, payload, route) => {
  navigate(`/${route}`)
  dispatch(homeStateActions.setScreenName(payload))
}

export const findUrlParameter = (parameterName) => {
  var result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export const detectBrowser = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName
}


export const downloadFileInBrowser = async ({ previewUrl, documentTitle }) => {
  // let browserName = detectBrowser()
  
  const { urls, types } = modifyDownloadableUrl(previewUrl)

  const response = await chatBoxService.downloadChatMessage({
    urls: urls,
    isDownload: true,
  }).then((res => {
    return res.result
  }));
  downloadFileInChrome({ urls: response, documentTitle: documentTitle, types: types })

  return
  // axios.get(url, {
  //   responseType: 'blob',
  // })
  // .then((res) => {
  //   console.log('shinchan',res)
  //   if(browserName==='safari')
  //   fileDownload(res.data, documentTitle+`.${type}`)
  //   else{
  //     downloadFileInChrome({url:previewUrl,documentTitle:documentTitle,type:type})
  //   }
  // })
}
export const previewDocumentInBrowser=async({previewUrl,documentTitle})=>{
  const { urls } = modifyDownloadableUrl(previewUrl)
  const response = await chatBoxService.downloadChatMessage({
    urls: urls,
    isDownload: false,
  }).then((res => {
    return res.result
  }));
    const newWindow = window.open(response[0], "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;

}
export const modifyDownloadableUrl = (urls = []) => {
  var modifiedUrls = []
  var modifiedTypes = []
  for (let i = 0; i < urls.length; i++) {
    var url = urls[i]
    url = url.replace('https://view.officeapps.live.com/op/embed.aspx?src=', '')
    url = decodeURIComponent(url)
    var type = url.split('?')[0].split('.')
    type = type[type.length - 1]
    url = url.replaceAll(' ', '%20')
    modifiedUrls.push(url)
    modifiedTypes.push(type)
  }
  return { urls: modifiedUrls, types: modifiedTypes }
}
const downloadFileInChrome = ({ urls, documentTitle, types }) => {
  var interval = setInterval(download, 1000, urls);
  function download(urls) {
    var url = urls.pop();
    var a = document.createElement("a");
    a.setAttribute('href', url);
    a.setAttribute('download', '');
    a.click();

    if (urls.length === 0) {
      clearInterval(interval);
    }
  }

}

export const getTypeOfUrl=({url})=>{

  const docTypes=new Map()
  docTypes.set('image',['png','jpeg','jpg'])
  docTypes.set('doc',['doc','docx'])
  docTypes.set('excel',['xlsx'])
  docTypes.set('csv',['csv'])
  docTypes.set('pdf',['pdf'])
  docTypes.set('video',['avi','mkv','mp4','mov'])

  var type = url.split('.')
  type = type[type.length - 1]

  var returnVal=null
  for (const [key, value] of docTypes) {
    if(value.includes(type?.toLowerCase())){
      returnVal=key
      break
    }
  }
 
   return returnVal
}


export function cleanObj(obj) {
  var propNames = Object.getOwnPropertyNames(obj);
  for (var i = 0; i < propNames.length; i++) {
    var propName = propNames[i];
    if (obj[propName] === null || obj[propName] === undefined||obj[propName]==="") {
      delete obj[propName];
    }
  }
  return obj
}


export const convertNumberIntoWords = (num) =>{
  var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
  var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
      if ((num = num.toString()).length > 9) return 'overflow';
      let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return; var str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
      return str;
}

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string' || string.length === 0) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const convertUTCToISTUtility = (javaLocalDateTime, dateFormatReturn = false) => {
  // Create a JavaScript Date object using the UTC time from Java LocalDateTime
  // The timestamp given is in nanoseconds, so we divide by 1,000,000 to get milliseconds
  const timestampInMillis = javaLocalDateTime.nano / 1000000 + new Date(Date.UTC(
      javaLocalDateTime.year,
      javaLocalDateTime.monthValue - 1,
      javaLocalDateTime.dayOfMonth,
      javaLocalDateTime.hour,
      javaLocalDateTime.minute,
      javaLocalDateTime.second
  )).getTime();

  // Create a new Date object using the UTC timestamp
  const date = new Date(timestampInMillis);
  // Use Intl.DateTimeFormat to convert the time to IST (Indian Standard Time) (UTC +5:30)
  if (dateFormatReturn) {
      // Format the date to the desired format (01 May, 24)
      const options = {
          day: '2-digit',
          month: 'short',   // Short month abbreviation
          year: '2-digit',  // Two-digit year
          timeZone: 'Asia/Kolkata', // IST time zone
      };
      const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
      return formattedDate;

  } else {
      const options = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,        // 12-hour format
          timeZone: 'Asia/Kolkata', // IST time zone
      };
      // Format the time to the desired format (HH:mm A)
      const timeInIST = new Intl.DateTimeFormat('en-US', options).format(date);
      return timeInIST;
  }

}

export const eventBucketLogoUrl = 'https://img.pristyncare.com/new_brand/elements/attendance/'