import { Box, Container, CssBaseline, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { convertUTCToISTUtility, eventBucketLogoUrl } from "../../../../_helpers";
import "./styles.css";

function HistoryList({ checkOutToHisId }) {
    const dateRangeEventHistoryList = useSelector(state => state.getAttendanceData?.dateRangeEventHistoryList)

    const dateFormat = (data) => {
        const { year, monthValue, dayOfMonth } = data;
        const month = monthValue - 1;
        const day = dayOfMonth + 1;
        const date = new Date(year, month, day);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };

    const convertMinutesToHours = (minutes) => {
        let hours = Math.floor(minutes / 60); // Whole hours
        let remainingMinutes = minutes % 60; // Remaining minutes
        if (remainingMinutes) {
            return `${hours} Hrs ${remainingMinutes} Minutes`;
        } else {
            return `${hours} Hrs`
        }
    }

    const convertToHrs = (minutes) =>{
        return Math.floor(minutes / 60);
    }

    return (
        <>
            <CssBaseline />
            <div>
                <List sx={{ width: '100%', maxWidth: '100%', bgcolor: '#fff', display: 'flex', flexDirection: "column", gap: "15px", padding: '15px' }}>
                    {
                        dateRangeEventHistoryList && Array.isArray(dateRangeEventHistoryList) && dateRangeEventHistoryList.length
                            ? dateRangeEventHistoryList.map((item) => {
                                return (
                                    <>
                                        <ListItem sx={{ gap: '1rem', bgcolor: '#F9FAFB', padding: "15px", borderRadius: "6px", justifyContent: 'space-between' }}
                                            onClick={() => checkOutToHisId(dateFormat(item.eventTime))}
                                        >
                                            <Typography sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                                <Box
                                                    sx={{
                                                        width: 40,
                                                        height: 69,
                                                        background: ' #FFFFFF',
                                                        color: "#3476CD",
                                                        border: '1px solid #E4E4E7',
                                                        borderRadius: '4px',
                                                        bgcolor: '#fff',
                                                        boxShadow: "",
                                                        fontSize: '12px',
                                                        padding: '8px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                        flexDirection: 'column',
                                                        textTransform: 'capitalize'
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: "16px" }} variant="span" component="span">
                                                        {item?.eventTime?.dayOfMonth}
                                                    </Typography>
                                                    {item?.eventTime?.month.substring(0, 3).toLowerCase()}
                                                    <br />
                                                    {item?.eventTime?.dayOfWeek.substring(0, 3).toLowerCase()}
                                                </Box>
                                                <Box sx={{ width: '100%' }}>
                                                    <ListItemText
                                                        sx={{ margin: "0 0 10px 0", padding: "0px" }}
                                                        primary={
                                                            <div className="content-wrapper">
                                                                <Typography className="address">
                                                                    {item?.hospitalName}
                                                                    {item?.hospitalAddress ? ' - ' + item?.hospitalAddress : ''}
                                                                </Typography>
                                                                <Typography className="address-item-count">
                                                                    {item?.eventsCount ? '+' + item?.eventsCount : ``}
                                                                </Typography>
                                                            </div>
                                                        }
                                                        secondary={
                                                            <div className="check-in-time-wrapper">
                                                                <Typography>
                                                                    {convertUTCToISTUtility(item.eventTime)}
                                                                </Typography>
                                                                <Typography>
                                                                    <img src={eventBucketLogoUrl + 'arrow_right_alt.svg'} alt='arrow right logo' />
                                                                </Typography>
                                                                <Typography>
                                                                    {
                                                                        item.checkOutTime ?
                                                                            convertUTCToISTUtility(item.checkOutTime)
                                                                            : ``
                                                                    }
                                                                </Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                    <Typography className="check-in-duration" sx={{ color: convertToHrs(item.duration ) <= 2 ? '#CB4C4C' : '#4AA03C' }}>
                                                        Duration  - {item.duration ? item.duration <= 60 ? item.duration + ' Minutes' : convertMinutesToHours(item.duration ) : 0 + ' Minutes'}
                                                    </Typography>
                                                </Box>
                                            </Typography>
                                            <Typography>
                                                <Box alignItems="center">
                                                    <img src={eventBucketLogoUrl + 'next-arrow.svg'} alt='next-arrow logo' />
                                                </Box>
                                            </Typography>
                                        </ListItem >

                                        {/* <Divider variant="inset" component="li" /> */}
                                    </>
                                )
                            })
                            :
                            <Box>
                                <Typography sx={{ height: '100%' }}>
                                    <img src={eventBucketLogoUrl + 'event-empty.svg'} alt='event empty' />
                                </Typography>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        color: '#000',
                                        fontSize: '1.2rem',
                                        margin: '3rem 0'
                                    }}
                                >
                                    No event history found!
                                </Typography>
                            </Box>
                    }
                </List>

            </div >
        </>
    )
}

export default HistoryList;