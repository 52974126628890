import { Box, Button, CssBaseline, Grid, Typography } from "@mui/material";
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from "react";
import useWindowSize from "../../../_helpers/useWindowSize";
import CustomSubHeader from "../components/customSubHeader";
import HistoryList from "../components/historyList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { activityConstants } from "../../../_constants";
import { getActivitiesActions } from "../../../_actions";


// Filter Component
const Filter = ({ filter, dateRangeOptions, onChange, onClickDateRange }) => {
    const { type, key, placeholder } = filter;
    const [startDate, endDate] = dateRangeOptions;

    const CustomDateInput = forwardRef(({ value, onClick, className }, ref) => (
        <button className={className} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    switch (type) {
        case 'daterange':
            return (
                <Box sx={{ margin: '15px' }}>
                    <Typography
                        sx={{ fontSize: '10px', color: "#393939", lineHeight: "20px" }}
                    >
                        Select Date Range
                    </Typography>
                    <div className="date-range-picker">
                        <DatePicker
                            showIcon
                            toggleCalendarClick
                            swapRange
                            selectsRange={true}
                            selected={endDate}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                onChange(update, key);
                            }}
                            isClearable={true}
                            maxDate={new Date()}
                            dateFormat="d MMM, yy"
                            placeholderText={placeholder}
                            dropdownMode="select"
                            calendarContainer={({ children }) => (
                                <div className="custom-button-wrapper" style={{ display: "flex" }}>
                                    <div className="buttons-list">
                                        <span>
                                            <Button variant="outlined" onClick={() => onClickDateRange('yesterday')} sx={{ width: '85px', color: 'black', border: '0.5px solid #919BAA', lineHeight: '18px' }}>
                                                Yesterday
                                            </Button>
                                        </span>
                                        <span>
                                            <Button variant="outlined" onClick={() => onClickDateRange('today')} sx={{ width: '85px', color: 'black', border: '0.5px solid #919BAA', lineHeight: '18px' }}>
                                                Today
                                            </Button>
                                        </span>
                                        <span>
                                            <Button variant="outlined" onClick={() => onClickDateRange('month')} sx={{ width: '85px', color: 'black', border: '0.5px solid #919BAA', lineHeight: '18px' }}>
                                                This Month
                                            </Button>
                                        </span>
                                    </div>

                                    {/* Calendar */}
                                    <div style={{ position: "relative", flexGrow: 1 }}>
                                        {children}
                                    </div>
                                </div>
                            )}
                            customInput={<CustomDateInput className="custom-date-input" />}
                            popperPlacement="bottom-start"
                        />
                    </div>
                </Box>
            )
        default:
            return null;
    }
};

function LoginHistory() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { isMobile } = useWindowSize();
    const mainContainerRef = useRef(undefined);
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 7); //
    const [dateRange, setDateRange] = useState([startDate, endDate]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const role = useSelector(state => state.authentication.user.roles) || ''
    const isGeoLocation = role?.otherRoles && role?.otherRoles.find((location) => (location === 'Geo Location Enabled'))

    const debouncedFetchedFilterData = useRef(
        debounce((dateRange) => {
            console.log('------debounce');
            if (dateRange[0] && dateRange[1]) {
                fetchDateRangeEventsHistoryData(dateRange);
            }
        }, 500)
    ).current;

    const setDateWithRange = useCallback((type = '') => {
        const currentDate = new Date();
        const endDate = new Date();

        switch (type) {
            case 'today':
                const todayDate = [new Date(currentDate.setDate(currentDate.getDate())), new Date(currentDate.setDate(currentDate.getDate()))]
                setDateRange(() => ([...todayDate]));
                break;
            case 'yesterday':
                const yesterdayDate = [new Date(currentDate.setDate(currentDate.getDate() - 1)), new Date(currentDate.setDate(currentDate.getDate()))]
                setDateRange(() => ([...yesterdayDate]));
                break;
            case 'month':
                const monthDate = [new Date(currentDate.setDate(currentDate.getDate() - new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate())), endDate]
                setDateRange(() => ([...monthDate]));
            default:
                break;
        }

        const dateCalenHideOnSelect = document.querySelector('.react-datepicker__tab-loop');
        if (dateCalenHideOnSelect && !dateCalenHideOnSelect.classList.contains('d-none')) {
            dateCalenHideOnSelect.classList.add('d-none')
        }

        const observer = new MutationObserver(() => {
            const reactDatepickerIgnoreOnclickoutside = document.querySelectorAll('.react-datepicker-ignore-onclickoutside');

            if (reactDatepickerIgnoreOnclickoutside && reactDatepickerIgnoreOnclickoutside.length > 0) {
                reactDatepickerIgnoreOnclickoutside.forEach((item) => {
                    item.classList.remove('react-datepicker-ignore-onclickoutside');
                });
            }
        });

        // Observe for changes in the DOM
        observer.observe(document.body, { childList: true, subtree: true });
    }, [])


    const handleDateRangeFilterChange = useCallback((update, key) => {
        setDateRange(() => ([...update]))
    }, [])

    useEffect(() => {
        debouncedFetchedFilterData(dateRange);
    }, [dateRange]);



    const fetchDateRangeEventsHistoryData = async (dateRange) => {
        let reqParams = {
            startDate: new Date(dateRange[0]).toLocaleDateString('en-CA'),
            endDate: new Date(dateRange[1]).toLocaleDateString('en-CA')
        }
        dispatch({
            type: activityConstants.GET_DATE_RANGE_ATTENDANCE_EVENT_FETCHED,
            dateRangeEventHistoryList: [],
        })

        dispatch(getActivitiesActions.getDateRangeEventList(reqParams))
    }

    const navigateToRoute = () => {
        if (window?.JBridge) {
            window.JBridge.handleBackCheckInApp()
        }
    }
    const routeToHistoryId = (eId) => {
        navigate(`/activity/history/${eId}?check=true`)
    }

    const isJBridgeEnable = () => {
        return window?.JBridge;
    }

    return (
        <>
            <CssBaseline />
            <CustomSubHeader title="Login History" backToRoute={navigateToRoute} backArrowActive={isJBridgeEnable() ? true : false} />
            <Grid
                container
                ref={mainContainerRef}
                sx={{ height: `100%`, display: "flex", pt: 0, position: "relative", height: "auto" }}
                data-component="connectedHomeScreen"
            >
                <Grid item xs={12}
                    sx={{ background: "#fff" }} >
                    <Filter
                        key={'datarange'}
                        filter={{
                            type: "daterange",
                            key: "daterange",
                            placeholder: "Date Range"
                        }}
                        selectedOptions={selectedOptions}
                        dateRangeOptions={dateRange}
                        onClickDateRange={setDateWithRange}
                        onChange={handleDateRangeFilterChange}
                    />
                </Grid>

                <Grid item xs={12}
                    sx={{
                        background: "#f5f5f5 0% 0% no-repeat padding-box;",
                        maxHeight: "100%",
                        overflowY: "auto",
                        maxWidth: "100%",
                        overflowX: "hidden",
                        position: "relative",
                        width: ['100%', "100%", "unset"],
                        minWidth: "100%"
                    }}
                    id="historyList"
                    className={"drawer-nonactive"}
                >
                    <HistoryList checkOutToHisId={routeToHistoryId} />
                </Grid>
            </Grid>
        </>
    )
}

export default LoginHistory;