import { toast } from "react-toastify";
import { getTokenFromLocal, handleResponse } from "../_helpers/handleResponseMiddleware";

let baseUrl = process.env.REACT_APP_BASE_URL;

export function getAttendanceListService(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${baseUrl}/api/v1/attendance`,
    requestOptions
  )
    .then(handleResponse)
    .then(res => res)
    .catch(function (error) {
      console.log({ error })
      toast.error(error.message)
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export function checkInDataService(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...params }),
  };
  return fetch(
    `${baseUrl}/api/v1/attendance`,
    requestOptions
  )
    .then(handleResponse)
    .then(res => res)
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export function todayEventHistoyService(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  };
  return fetch(
    `${baseUrl}/api/v1/attendance/day-events?day=${params}`,
    requestOptions
  )
    .then(handleResponse)
    .then(res => res)
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export function dateRangeEventHistoyService(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${baseUrl}/api/v1/attendance/events?startDate=${params.startDate}&endDate=${params.endDate}`,
    requestOptions
  )
    .then(handleResponse)
    .then(res => res)
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export function nearestHospitalService(params) {
  
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  };
  return fetch(
    `${baseUrl}/api/v1/attendance/nearest-hospital?longitude=${params.lng}&latitude=${params.lat}`,
    requestOptions
  )
    .then(handleResponse)
    .then(res => res)
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response ;
    });
}

export function applyAttendanceService(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: params,
  };
  return fetch(
    `${baseUrl}/api/v1/attendance/mark-attendance`,
    requestOptions
  )
    .then(handleResponse)
    .then(res => res)
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response ;
    });
}

export const activityService = {
  getAttendanceListService,
  checkInDataService,
  todayEventHistoyService,
  dateRangeEventHistoyService,
  applyAttendanceService,
  nearestHospitalService
};
