import { KeyboardArrowDown, Logout } from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppBar, Avatar, Box, Button, Container, IconButton, Modal, Popover, Toolbar, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMobile from '../../../assets/mobileLogo.svg';
import { alertActions, cacheStorageActions, chatBoxActions, configActions, documentsPopupActions, getActivitiesActions, groupInfoPopupActions, homeStateActions, hospitalActions, patientActions, socketActions, userActions } from "../../../_actions";
import { checklistActions } from "../../../_actions/checklist.actions";
import { changeScreen } from "../../../_helpers";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { resetSelectedStatesToInitial } from "../../../_helpers/getInitialState";
import useWindowSize from "../../../_helpers/useWindowSize";
import { headerTabs } from "./HeaderData";
import NotificationComponent from './Notification'
import "./index.css";
import { checkInDataService, nearestHospitalService } from "../../../_services";
import { toast } from "react-toastify";
import { rolesAccessList } from "../../../Routing/RoutingConstants";
import GoogleMapComponent from "../../Activity/components/customGoogleMap";

const Header = () => {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [hospitalData, setHospitalData] = useState({})
  const [hasLocationPermission, setHasLocationPermission] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const latitude = searchParams.get("lat");
  const longitude = searchParams.get("lng");
  const [locationData, setLocationData] = useState({ lat: latitude || null, lng: longitude|| null, accuracy: null, timestamp: null });
  const mapKey = "AIzaSyDplpH5TL0vHanOH5BC88oi0UcT6uqYqZY"
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const open2 = Boolean(anchorEl2);
  const id = open2 ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getStatus = useSelector(state=>state?.getAttendanceData?.attendanceList)

  const mainTitle = useSelector((state) => state.homeState.mainTitle);
  const showChatBox = useSelector((state) => state.homeState.showChatBox);
  const checkUpdatedBuild = useSelector(
    (state) => state.homeState.checkUpdatedBuild
  );
  const blockChatBox = useSelector(state => state.chatBox.blockChatBoxScreen)

  const logoutAnchorEL = useSelector((state) => state.homeState.logoutAnchorEL);
  const role = useSelector(state => state.authentication.user.roles?.role) || ''
  const otherRoles = useSelector((state) => state.authentication.user?.roles?.otherRoles)

  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const name = useSelector((state) => state.authentication.user.name);
  const { isMobile } = useWindowSize();
  const [openDrawer, setOpenDrawer] = useState(false)

  const roleGeoLocation = () => {
    if (otherRoles && Array.isArray(otherRoles) && otherRoles.length) {
      const hasRoleExit = otherRoles.find((location) => (location === 'Geo Location Enabled' || location === 'Only Geo User'))
      return hasRoleExit;
    }
  }
  const roleGeoOnlyLocation = () => {
    if (otherRoles && Array.isArray(otherRoles) && otherRoles.length) {
      const hasRoleExit = otherRoles.find((location) => (location === 'Only Geo User'))
      return hasRoleExit;
    }
  }
  const userRole = roleGeoLocation()

  const handlePopOverClick = (event, dispatch) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = (dispatch) => {
    setAnchorEl2(null);
  };

  const setActiveTab = (tab) => {
    console.log("tab1", tab)
    dispatch(homeStateActions.setActiveTab(tab))
    if (tab === 'home') {
      changeScreen(dispatch, navigate, { hospitalList: true, patientList: false, chatBox: false }, 'home')
    }
  };
  const toggleDrawer = (value) => {
    if(!location.pathname?.includes('login') && userRole){
      dispatch(getActivitiesActions.getAttendanceList())
    }
    setOpenDrawer(value)
  }

  //to trigger PWA installation

  let deferredPrompt = "something";
  const [tempState, setTempState] = useState()

  window.addEventListener('beforeinstallprompt', (e) => {
    console.log('installation event is fired', e)
    setTempState(e)
    deferredPrompt = e;
  });


  const handleInstallAppClick = async (e) => {
    toggleDrawer(false)
    console.log('this is deferredPrompt', tempState)
    if (tempState !== null) {
      tempState.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        deferredPrompt = null;
      }
    }
  }




  const activeTab = useSelector((state) => state.homeState.activeTab);
  const handleLogoutClick = (dispatch) => {
    resetSelectedStatesToInitial(dispatch, [
      {
        action: userActions.updateFullState,
        stateName: "authentication",
        addtionalStateData: { loggingIn: false },
      },
      { action: alertActions.updateFullState, stateName: "alert" },
      { action: hospitalActions.updateFullState, stateName: "hospitals" },
      { action: patientActions.updateFullState, stateName: "patients" },
      { action: chatBoxActions.updateFullState, stateName: "chatBox" },
      {
        action: documentsPopupActions.updateFullState,
        stateName: "documentsPopup",
      },
      {
        action: groupInfoPopupActions.updateFullState,
        stateName: "groupInfoPopup",
      },
      { action: configActions.updateFullState, stateName: "config" },
      { action: homeStateActions.updateFullState, stateName: "homeState" },
      {
        action: cacheStorageActions.updateFullState,
        stateName: "cacheStorage",
      },
      {
        action: socketActions.updateFullState,
        stateName: "socketConnectivity",
      },
      {
        action: checklistActions.updateFullState,
        stateName: 'checklistSummary'
      }
    ]);

    dispatch(userActions.logout());
    handleClose()
    localStorage.clear();
  };

  const requestLocationPermission = () => {
    if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              console.log("User allowed location:", position);
              setLocationData({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                accuracy: position.coords.accuracy,
                timestamp: position.timestamp || Date.now(),
              });
              setHasLocationPermission(true);
            },
            (error) => {
              console.log("User denied location:", error);
              setHasLocationPermission(false);
              toast.warning("Please allow location access for check-in.")
            }
    )
  }
  else {
    toast.warning("Geolocation is not supported in this browser.")
    console.log("Geolocation is not supported in this browser.");
  }
}

const handleLatLng = () =>{
  if(latitude && longitude){
    setLocationData({
      lat: latitude,
      lng: longitude,
      accuracy: 'High',
      timestamp: Date.now(),
    });
    setHasLocationPermission(true);
  }
  else{
    requestLocationPermission();
  }
}

useEffect(() => {
  if(!location.pathname?.includes('login') && userRole){
    handleLatLng()
    dispatch(getActivitiesActions.getAttendanceList())
  }
}, [otherRoles]);

const handleCheckIn = () => {
  if (!hasLocationPermission) {
    toggleDrawer(false);
    handleLatLng()
    return;
  }

  if (!locationData?.lat || !locationData?.lng) {
    toast.error("Unable to locate your current location");
    toggleDrawer(false);
    return;
  }

  const params = {
    lng: locationData.lng,
    lat: locationData.lat,
  };

  nearestHospitalService(params)
    .then((res) => {
      if (res?.status === "Success") {
        const hospitalFound = res?.result?.hospitalData?.found;

        if (hospitalFound) {
          navigate(`/activity/check-in`);
        } else {
          setHospitalData(res?.result?.hospitalData);
          setIsModalOpen(true);
        }
      } else {
        toast.error("Unable to find the nearest hospital");
      }
    })
    .catch((error) => {
      console.error("Error fetching nearest hospital:", error);
      toast.error("Unable to find the nearest hospital");
    })
    .finally(() => {
      toggleDrawer(false);
    });
};


  const handleCheckOut = () => {
    navigate(`/activity/check-out`);
    toggleDrawer(false);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTryAgainModal = () => {
    setIsModalOpen(false);
    handleLatLng()
    handleCheckIn()
  };

  return (
    <>
      <AppBar
        sx={{
          m: "0 !importa50nt",
          p: "0 !important",
          top: "0",
          boxShadow: "none",
          background: "#3D7CC9",
          position: 'static'
        }}
      >
        <Toolbar
          sx={{
            background: "#00263E",
            m: "0 !important",
            p: "0 !important",
            boxShadow: "0px 3px 6px #00000029",
            height: "64px",
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Container
            sx={{
              m: "0",
              mx: "auto",
              px: "1rem !important",
              display: "flex",
              w: "100vw",
              maxWidth: "100% !important",
              gridTemplate: "auto/ 1fr 1fr 1fr",
              justifyContent: "space-between",
              alignItems: "center",
              height: '100%'
            }}
          >

            <img
              src={isMobile ? logoMobile : "https://img.pristyncare.com/new_brand%2Felements%2Flogo.svg"}
              alt="PristynCare-logo"
              style={{
                maxWidth: isMobile ? "26px" : "150px",
              }}
            />
            <Typography
              variant="h1"
              fontWeight="bold"
              align="center"
              className="responsiveHead"
              sx={{ display: ['block', 'block', (mainTitle === 'Hospital Chat') ? 'none' : 'block'], color: "#fff", fontSize: "1.8rem", whiteSpace: "nowrap", }}
            >
              {!!mainTitle?.length ? mainTitle : "Hospital Chat"}
            </Typography>

            {loggedIn ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <Box sx={{ display: ['none', 'none', 'flex'], height: "100%" }}>
                  {
                  !roleGeoOnlyLocation() ?
                  headerTabs.map((item, i) => {
                    // console.log("item.key",item.key)
                    return (
                      (!item.roles || item.roles.some((item) => item.toLowerCase() === role.toLowerCase())) &&
                      <div
                        className="headerTab 1"
                        id={item.title}
                        style={{ position: "relative", fontWeight: activeTab == item.key ? 700 : '', cursor: "pointer" }}
                        key={i}
                        onClick={() => { setActiveTab(item.key); navigate(`/${item.key}`) }}
                      >
                        <div>

                          {item.title}
                        </div>
                        {activeTab == item.key && <div style={{ position: "absolute", bottom: "4px", width: "100%", height: "4px", background: "#3276cf", minWidth: "48px", borderRadius: "5px" }}></div>}
                      </div>

                    );
                  })
                : ``
                }
                </Box>
                <Box>
                  <NotificationComponent />
                </Box>
                <Box
                  sx={{
                    display: ['none', 'none', 'flex'],
                    justifyContent: "center",
                    alignItems: "center",
                    gap: ".5rem",
                    fontWeight: "500",
                    whiteSpace: "nowrap"
                  }}
                >
                  <Avatar></Avatar>

                  <Typography
                    sx={{
                      fontWeight: "500",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {name}
                  </Typography>

                  <Box>
                    <IconButton
                      onClick={(e) => handlePopOverClick(e, dispatch)}
                      aria-describedby={"logout-popOver"}
                      sx={{
                        color: "#fff",
                        p: 0,
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </Box>
                </Box>
                <Box onClick={() => toggleDrawer(true)} sx={{ display: ['flex', 'flex', 'none'], visibility: blockChatBox ? "hidden" : 'unset' }}>
                  <MoreVertIcon />
                </Box>

                <Popover
                  id={id}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={() => handleClose(dispatch)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Button
                    aria-label="logout"
                    onClick={() => handleLogoutClick(dispatch)}
                    color="warning"
                    sx={{ p: ".5em 1em !important" }}
                    startIcon={<Logout />}
                  >
                    Logout
                  </Button>
                </Popover>
              </Box>
            ) : (
              <div> </div>
            )}
          </Container>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        PaperProps={{ style: { height: "278px", minWidth: "250px", background: "#00263E", color: "white", padding: "10px 0px" } }}
      >
        {
        !roleGeoOnlyLocation() ?
        headerTabs.map((item, i) => {
          return (
            (!item.roles || item.roles.some((item) => item.toLowerCase() === role.toLowerCase())) &&
            <div
              className="headerTab 2"
              style={{ position: "relative", cursor: "pointer", display: "flex" }}
              key={i}
              onClick={() => { setActiveTab(item.key); navigate(`/${item.key}`); toggleDrawer(false) }}
            >
              {<Box sx={{ display: ['block', 'block', 'none'], padding: "0px 10px 0px 10px", width: "40px" }}>
                <div style={{ height: "20px", width: "4px", background: activeTab == item.key ? "white" : "#00263E" }}></div>
              </Box>}
              <div style={{ textDecoration: activeTab == item.key ? "underline" : "", width: "calc(100% - 40px)", padding: "10px 0px 10px 0px", height: "40px" }}>
                {item.title}
              </div>

            </div>

          );
        })
        : ``
      }
        {
          roleGeoLocation() ?
            <>
              <div
                className="headerTab 3"
                style={{ position: "relative", cursor: "pointer", display: "flex" }}
                key={'checkIn'}
                onClick={getStatus?.result?.event?.toLowerCase() ==='checkin'?handleCheckOut :handleCheckIn}
              >
                {<Box sx={{ display: ['block', 'block', 'none'], padding: "0px 10px 0px 10px", width: "40px" }}>
                  <div style={{ height: "20px", width: "4px", background: "#00263E" }}></div>
                </Box>}
                <div style={{ width: "calc(100% - 40px)", padding: "10px 0px 10px 0px", height: "40px" }}>
                {getStatus?.result?.event?.toLowerCase() ==='checkin' ? 'Check Out':'Check In'}
                </div>
              </div>
              <div
                className="headerTab 3"
                style={{ position: "relative", cursor: "pointer", display: "flex" }}
                key={'loginHistory'}
                onClick={() => { navigate(`/activity/history`); toggleDrawer(false); }}
              >
                {<Box sx={{ display: ['block', 'block', 'none'], padding: "0px 10px 0px 10px", width: "40px" }}>
                  <div style={{ height: "20px", width: "4px", background: "#00263E" }}></div>
                </Box>}
                <div style={{ width: "calc(100% - 40px)", padding: "10px 0px 10px 0px", height: "40px" }}>
                  Login History
                </div>
              </div>
            </>
            : ``
        }
        <div
          className="headerTab 3"
          style={{ position: "relative", cursor: "pointer", display: "flex" }}
          key={'logout'}
          onClick={() => { navigate(`/login`); toggleDrawer(false); handleLogoutClick(dispatch) }}
        >
          {<Box sx={{ display: ['block', 'block', 'none'], padding: "0px 10px 0px 10px", width: "40px" }}>
            <div style={{ height: "20px", width: "4px", background: "#00263E" }}></div>
          </Box>}
          <div style={{ width: "calc(100% - 40px)", padding: "10px 0px 10px 0px", height: "40px" }}>
            Logout
          </div>
        </div>
        {/* {console.log('this is temp state',tempState)} */}
        {tempState && <div
          className="headerTab 3"
          style={{ position: "relative", cursor: "pointer", display: "flex" }}
          key={'installApp'}
          onClick={(e) => { handleInstallAppClick(e) }}
        // onClick={() => { navigate(`/login`); toggleDrawer(false); handleLogoutClick(dispatch) }}
        >
          {<Box sx={{ display: ['block', 'block', 'none'], padding: "0px 10px 0px 10px", width: "40px" }}>
            <div style={{ height: "20px", width: "4px", background: "#00263E" }}></div>
          </Box>}
          <div id="installApp" style={{ width: "calc(100% - 40px)", padding: "10px 0px 10px 0px", height: "40px" }}>
            Install to home screen
          </div>
        </div>}
      </Drawer>

      {
        roleGeoLocation() ? 
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              maxWidth: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              height:'max-content',
              maxHeight:'480px',
              overflowY:'auto',
              paddingTop:'20px',
              paddingBottom:'30px',
              borderRadius: '10px',
              left: '50%',
              transform: window.innerWidth <= 500 ? 'translate(-50%)' :'translate(-50%, -50%)',
              bottom: window.innerWidth <= 500 ? 0 : "50%",
              top: window.innerWidth > 500 ? "50%" : "auto",
              borderBottomLeftRadius: window.innerWidth <= 500 ? 0 : '10px',
              borderBottomRightRadius: window.innerWidth <= 500 ? 0 : '10px',
              textAlign: "center",
              display: 'flex',
              flexDirection: 'column',
              gap:'10px',
              border:'unset'
            }}
          >
            <span className="outOfRangeHeading">Out of Range</span>
            <span className="outOfRangeSubHeading">You are {Math.floor(hospitalData?.distance || 10) + " " + (hospitalData?.metrics || 'm')} away from nearest
            {" " + hospitalData?.hospitalName || 'Pristyn Hospital'}.</span>
            <span className="outOfRangeSubText">(You should be within {hospitalData?.allowedDistance|| 300}m from hospital)</span>

            {locationData.lat && locationData.lng ? (
            // <a
            //   href={`https://www.google.com/maps/search/?api=1&query=${locationData.lat},${locationData.lng}`}
            //   target="_blank"
            //   rel="noopener noreferrer"
            //   height="173px"
            // >
            //   <iframe
            //     title="User Location"
            //     width="100%"
            //     className="checkInMainMap"
            //     height="170px"
            //     style={{
            //       border: "none",
            //       pointerEvents: "none",
            //     }}
            //     src={`https://www.google.com/maps/embed/v1/place?key=${mapKey}&q=${locationData.lat},${locationData.lng}`}
            //     allowFullScreen
            //   ></iframe>
            // </a>
            <GoogleMapComponent mapHeight="170px" userLat={locationData.lat} userLng={locationData.lng} />
          ) :""}
            <div className="outOfRangeAddressContainer">
              <LocationOnIcon sx={{color:'#F74A56'}}/>
              <span className="outOfRangeAddress"><strong>Nearest Hospital: </strong>
              {hospitalData?.hospitalName || hospitalData?.hospitalAddress 
              ? `${hospitalData?.hospitalName ? hospitalData.hospitalName + ", " : ""}${hospitalData?.hospitalAddress || ""}` 
              : "NA"}
              </span>
            </div>
            <Button
              variant="contained"
              sx={{ width: "90%", margin:'auto', fontSize:'16px', fontWeight:600,
                borderRadius:'10px', height:'44px', marginTop:'5px'
               }}
              onClick={handleTryAgainModal}
            >
              Try Again
            </Button>
          </Box>
        </Modal>
        :""
      }
    </>

  );
};

export default Header;
