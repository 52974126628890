import react, { useEffect, useRef } from "react";

const GoogleMapComponent = ({ userLat, userLng, mapHeight='116px' }) => {
    const mapRef = useRef(null);
    useEffect(() => {
      if (!window.google || !userLat || !userLng) return;
  
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: userLat, lng: userLng },
        zoom: 10,
        mapId: "GMB_MAP_ID",
      });
  
      new window.google.maps.Marker({
        position: { lat: userLat, lng: userLng },
        map,
        title: "User Location",
      });
  
    }, [userLat, userLng]);
  
    return <div className="mapLocationStyle" style={{height:mapHeight}} ref={mapRef}></div>;
  };

  export default GoogleMapComponent