import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CentralInputField } from '../component/CentralInputField/CentralInputField';
import CentralDateTimePicker from '../component/CentralDateTimePicker/CentralDateTimePicker';
import { CentralSelect } from '../component/CentralSelect/CentralSelect';
import { CentralModal } from '../component/CentralModal/CentralModal';
import { CentralFileUpload } from '../component/CentralUploadFile/CentralUploadFile';
import moment from 'moment';
import { CentralSummaryModal } from '../component/centralSummaryModal/CentralSummaryModal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Import the utc plugin
import { CentralDaLetterModal } from '../component/centralDaLetterModal/CentralDaLetterModal';

dayjs.extend(utc); // Extend dayjs with the utc plugin

const formatValueIfNumber = (val)=>{
    if (!val && val !== 0) {
        return val;
    }
    
    if (typeof val === 'number') {
        return `${Number.isInteger(val) ? val : val.toFixed(2)}`;
    }
    const hasRupeeSymbol = val.trim().startsWith('₹');
    if(hasRupeeSymbol){
        const num = parseFloat(val.replace(/[^\d.-]/g, ''));
        return `₹ ${Number.isInteger(num) ? num : num.toFixed(2)}`;
    }
    const num = Number(val)
     if (isNaN(num)) {
            return val;
      }
    return `${Number.isInteger(num) ? num : num.toFixed(2)}`
    }

    function formatDate(value, format) {
        const date = moment(value);
        if (!date.isValid()) {
          return '--';
        }
        return date.format(format);
      }


const renderCentralCommonComponent = (details, handleGlobalChanges, setGlobalValues=()=>{}, globalValues={}) =>{
    let commonComponent = ''

    const addExtraCss = () =>{
        if(details.key ==='subStatus' && details?.value && details?.value?.toLowerCase()==='feedback received'){
            return 'feedbackReceivedStatus'
        }
        return ''
    }
    
    switch(details.functionType){
        case 'text-read-only-link':
            commonComponent = (
                <div className='textReadOnlyRow'>
                    <span className='textReadOnlyLabel'>{details.label}</span>
                    <span className={`textReadOnlyValue}`}
                    >
                        {(details?.value === '' || details?.value === 'NA') ? 'NA' : <CentralDaLetterModal daLetterData={details.daLetterDocList} />}
                    </span>
                </div>
            )
            break;
        case 'text-read-only':
        commonComponent = (
            <div className='textReadOnlyRow'>
             <span className='textReadOnlyLabel'>{details.label}</span>
             <span className={`textReadOnlyValue ${addExtraCss()}`}>{details.format? formatDate(details.value,details.format): (formatValueIfNumber(details.value) || '--')}</span>
            </div>
            )
        break;
        case 'text-read-only-style': 
        commonComponent = (
            <div className='textReadOnlyRow'>
             <span className='textReadOnlyLabel'>{details.label}</span>
             <span className='textReadOnlyValue' style={{color:details.class}}>{formatValueIfNumber(details.value) || '--'}</span>
            </div>
            )
        break;
        case 'ok-surgery':
            commonComponent = (
            <div className='textReadOnlyRow'>
             <span className='textReadOnlyLabel okSurgeryText'>{details.label}</span>
             {details.value ?<div className='okSurgeryValueContainer'>
                <CheckCircleIcon sx={{fontSize:'11px', color:'white'}}/>
                <span className='textReadOnlySurgeryValue'>{details.value}</span>
             </div>:<span className='textReadOnlyValue'>--</span>}
            </div>
            )
        break;
        case 'modal-read-only':
            commonComponent = (
            <div className='textReadOnlyRow'>
             <span className='textReadOnlyLabel'>{details.label}</span>
             {details.value? <CentralModal modalTitle = {details.label} description = {details.value}/>:<span className='textReadOnlyValue'>--</span>}
            </div>
            )
        break;
        case 'input-text':
            commonComponent = (
            <CentralInputField jsonDetails = {details} handleGlobalChanges={handleGlobalChanges}/>
            )
        break;
        case 'input-numeric':
            const isPreValue = (details.key === "amount_collected_admission" || details.key==="total_collected_at_discharge")? 'Rs.':''
            commonComponent = (
            <CentralInputField type = {"number"} jsonDetails = {details} startAdornment = {isPreValue} handleGlobalChanges={handleGlobalChanges}/>
            )
        break;
        case 'input-dropDown':
            commonComponent = (
                details?.isHideField ? "" :<CentralSelect options = {details.options} jsonDetails = {details} handleGlobalChanges={handleGlobalChanges}/>
            )
        break;
        case 'input-date-time':
            commonComponent = (
            <CentralDateTimePicker jsonDetails = {details} handleGlobalChanges={handleGlobalChanges} setGlobalValues={setGlobalValues} globalValues={globalValues}/>
            )
        break;
        case 'upload-file':
            commonComponent = (
            <CentralFileUpload uniqueKey='admissionUploadFile' jsonDetails = {details} handleGlobalChanges={handleGlobalChanges}/>
            )
        break;
        case 'upload-file-discharge':
            commonComponent = (
            <CentralFileUpload uniqueKey='dischargeUploadFile' jsonDetails = {details} handleGlobalChanges={handleGlobalChanges}/>
            )
        break;
        case 'summary-modal':
            commonComponent = (
            <CentralSummaryModal />
            )
        break;
        case 'multi-select-dropDown':
            commonComponent = (
            <CentralSelect options = {details.options} jsonDetails = {details} isMultiSelect={true} handleGlobalChanges={handleGlobalChanges}/>
            )
        break;
        case 'upload-images':
            commonComponent = (
            <CentralFileUpload uniqueKey={'uploadImages'} isAmountShow = {false} jsonDetails = {details} handleGlobalChanges={handleGlobalChanges}/>
            )
        break;
        default: console.log('Invalid Function Type');
    }
    return commonComponent
}

function getValueAfterConvertStringToObjectKeys (obj, keys) {
    if (!keys || keys === null || typeof keys !== "string" || obj === null || !obj) {
        return ""
    }
    let allKeysArr = keys.split(".")
    let objWithValue = obj
    for (let i = 0;i<allKeysArr.length;i++) {
        if (objWithValue[allKeysArr[i]]!==0 && !objWithValue[allKeysArr[i]]) {
            return null
        }
        objWithValue = objWithValue[allKeysArr[i]]
    }
    return objWithValue
}

const formatCommonHeaderDate = (date) => {
    return dayjs(date).utc().set('hour', 18).set('minute', 30).set('second', 0).set('millisecond', 0).toISOString();
 };
 
const statusCategories = [
    {
      color: "#00FFEF",
      statuses: ['Tentative IPD', 'Deficit', 'Boarded the Cab', 'Admitted', 'PAC Cleared', 'Extra Test Suggested', 'PAC Not Cleared']
    },
    {
      color: "#B6EAA3",
      statuses: ['Ok for Surgery', 'In Surgery', 'Surgery Done', 'Pre-Sx financially cleared']
    },
    {
      color: "#E7D7A0",
      statuses: ['Billing in progress', 'Fit for Discharge', 'Discharge processed', 'Discharged']
    }
  ];
export {
    renderCentralCommonComponent,
    getValueAfterConvertStringToObjectKeys,
    formatCommonHeaderDate,
    statusCategories,
    formatValueIfNumber,
    formatDate
}