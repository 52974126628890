import React, { useEffect, useRef, useState } from "react";
import CustomSubHeader from "../components/customSubHeader";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { toast } from "react-toastify";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  applyAttendanceService,
  checkInDataService,
  nearestHospitalService,
} from "../../../_services";
import moment from "moment";
import { configActions, getActivitiesActions } from "../../../_actions";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapComponent from "../components/customGoogleMap";

const CheckInHome = ({ isCheckOut = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(useLocation().search);
  const latitude = +searchParams.get("lat");
  const longitude = +searchParams.get("lng");
  const [location, setLocation] = useState({
    lat: latitude || null,
    lng: longitude || null,
    accuracy: 'High',
    timestamp: null,
  });
  const [error, setError] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [batteryPercentage, setBatteryPercentage] = useState(50);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hospitalData, setHospitalData] = useState({});
  const [currentAddress, setCurrentAddress] = useState("");
  const mapKey = "AIzaSyDplpH5TL0vHanOH5BC88oi0UcT6uqYqZY";
  const role = useSelector((state) => state.authentication.user.roles) || "";
  const isGeoLocation =
    role?.otherRoles &&
    role?.otherRoles?.find((location) => location === "Geo Location Enabled");

  //   longitude: 77.0592861
  // latitude: 28.4031006

  function formatDateTime(obj) {
    const utcDate = new Date(
      Date.UTC(
        obj.year,
        obj.monthValue - 1,
        obj.dayOfMonth,
        obj.hour,
        obj.minute,
        obj.second
      )
    );
    const istDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    const nowIST = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );

    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
    const dateOptions = { year: "numeric", month: "short", day: "numeric" };

    if (istDate.toDateString() === nowIST.toDateString()) {
      return `Today at ${istDate.toLocaleTimeString("en-US", timeOptions)}`;
    }

    const yesterdayIST = new Date(nowIST);
    yesterdayIST.setDate(yesterdayIST.getDate() - 1);
    if (istDate.toDateString() === yesterdayIST.toDateString()) {
      return `Yesterday at ${istDate.toLocaleTimeString("en-US", timeOptions)}`;
    }

    return `${istDate.toLocaleDateString(
      "en-US",
      dateOptions
    )}, ${istDate.toLocaleTimeString("en-US", timeOptions)}`;
  }

  const getLocation = () => {
    setIsRefresh(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            accuracy: position.coords.accuracy,
            timestamp: position.timestamp || Date.now(),
          });
          setIsRefresh(false);
          setError(null);
        },
        (err) => {
          setIsRefresh(false);
          setError("Unable to fetch location. Please enable GPS.");
          toast.error(err.message);
          console.error("Geolocation error:", err);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
      );
    } else {
      setIsRefresh(false);
      setError("Geolocation is not supported by your browser.");
    }
  };

  const getBatteryStatus = async () => {
    if ("getBattery" in navigator) {
      try {
        const battery = await navigator.getBattery();
        setBatteryPercentage(Math.round(battery.level * 100));
      } catch (error) {
        console.error("Battery API error:", error);
      }
    } else {
      console.error("Battery API not supported in this browser.");
    }
  };

  const handleLatLng = () => {
    if (latitude && longitude) {
      setLocation({
        lat: latitude,
        lng: longitude,
        accuracy: "High",
        timestamp: Date.now(),
      });
      if (window?.JBridge) {
        const type = isCheckOut ? "check-out" : "check-in";
        window.JBridge.handleRefreshInApp(type);
      }
      setIsRefresh(false);
    } else {
      getLocation();
    }
  };

  useEffect(() => {
    dispatch(configActions.showSpinningLoader());
    if (!latitude && !longitude) {
      getLocation();
    }
    getBatteryStatus();
    dispatch(configActions.hideSpinningLoader());
  }, []);

  const nearestHospital = () => {
    const params = {
      lng: location.lng,
      lat: location.lat,
    };
    dispatch(configActions.showSpinningLoader());
    nearestHospitalService(params)
      .then((res) => {
        if (res && res.status === "Success") {
          if (res.result && res.result?.hospitalData) {
            setHospitalData(res?.result);
          }
        } else {
          toast.error("Unable to found nearest hospital");
        }
      })
      .catch((error) => {
        toast.error("Unable to found nearest hospital");
        console.log("Unable to found nearest hospital", error);
      })
      .finally(() => dispatch(configActions.hideSpinningLoader()));
  };

  useEffect(() => {
    if (location.lat && location.lng) {
      nearestHospital();

      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${mapKey}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.results?.length > 0) {
            console.log("map data...", data);
            setCurrentAddress(data.results[0].formatted_address);
          }
        })
        .catch((error) => console.error("Error fetching address:", error));
    }
  }, [location]);

  const navigateToRoute = () => {
    if (window?.JBridge) {
      window.JBridge.handleBackCheckInApp();
    }
  };

  const handleRefresh = () => {
    setIsRefresh(true);
    setTimeout(() => {
      handleLatLng();
    }, 1000);
  };

  const handleCapture = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPhoto({ url: imageUrl, file: file });
    }
  };

  const handleCheckIn = () => {
    if (!error && location.lat && location.lng) {
      dispatch(configActions.showSpinningLoader());
      const file = (photo && photo?.file) || "";
      const formData = new FormData();
      // formData.append("longitude", 51.9253);
      // formData.append("latitude", 14.2350);
      formData.append("longitude", location.lng);
      formData.append("latitude", location.lat);
      formData.append("battery", batteryPercentage || 0);
      formData.append("file", file);

      applyAttendanceService(formData)
        .then((res) => {
          if (res.status === "Success") {
            toast.success(
              isCheckOut
                ? "You are successfully checked out"
                : "You are successfully checked in"
            );
            dispatch(getActivitiesActions.getAttendanceList());
            if (window?.JBridge) {
              window.JBridge.handleBackCheckInApp();
            } else if (role === "Geo Location Enabled" || isGeoLocation) {
              navigate("/home");
            } else {
              const cuurentDate = new Date().toISOString().split("T")[0];
              navigate(`/activity/history/${cuurentDate}?check=true`);
            }
          } else {
            setIsModalOpen(true);
          }
        })
        .catch((err) => {
          console.error("Failed to check-in", err);
          toast.error(
            isCheckOut ? "Failed to check out" : "Failed to Check-In!"
          );
        })
        .finally(() => dispatch(configActions.hideSpinningLoader()));
    } else {
      toast.error("Unable to fetch location");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTryAgainModal = () => {
    setIsModalOpen(false);
    handleLatLng();
    handleCheckIn();
  };

  const isJBridgeEnable = () => {
    return window?.JBridge;
  };

  return (
    <div className="checkInMainContainer">
      <CustomSubHeader
        title={isCheckOut ? "Check-Out" : "Check-in"}
        backToRoute={navigateToRoute}
        backArrowActive={isJBridgeEnable() ? true : false}
      />
      <div className="checkInChildContainer">
        <div className="checkInMapContainer">
          <div className="checkInCurrentLocationContainer">
            <div className="checkInCurrentLocationLeftContainer">
              <LocationOnOutlinedIcon
                sx={{ fontSize: "24px", color: "#3476CD" }}
              />
              <div className="checkInCurrentLocationHeadingContainer">
                <span className="checkInCurrentLocationText">
                  Current Location
                </span>
                <span className="checkInCurrentLocationGpsText">
                  GPS Accuracy:{" "}
                  {!isRefresh && location.accuracy
                    ? `${
                        location.accuracy !== "High"
                          ? location.accuracy.toFixed(1) + " meters"
                          : "High"
                      }`
                    : isRefresh
                    ? "Fetching..."
                    : "High"}
                </span>
              </div>
            </div>
            <div className="checkInCurrentLocationRightContainer">
              <IconButton
                onClick={handleRefresh}
                className={isRefresh ? "rotating" : ""}
              >
                <RefreshOutlinedIcon
                  sx={{ fontSize: "24px", color: "#3476CD" }}
                />
              </IconButton>
            </div>
          </div>
          {location.lat && location.lng && !isRefresh ? (
            <GoogleMapComponent userLat={location.lat} userLng={location.lng} />
          ) : (
            <p className="loadingText">
              {!isRefresh ? error : "Fetching location..."}
            </p>
          )}
          {currentAddress ? (
            <span className="checkInMapLocation">{currentAddress}</span>
          ) : (
            ""
          )}
        </div>

        <div className="checkInLocationDetailsContainer">
          <span className="checkInLocationDetailsLabel">Hospital</span>
          <div className="checkInLocationDetailsValue">
            {hospitalData?.hospitalData?.hospitalName &&
            hospitalData?.hospitalData?.hospitalAddress
              ? `${hospitalData.hospitalData.hospitalName}, ${hospitalData.hospitalData.hospitalAddress}`
              : hospitalData?.hospitalData?.hospitalName ||
                hospitalData?.hospitalData?.hospitalAddress ||
                "NA"}
          </div>
        </div>
        <div className="checkInLocationDetailsContainer">
          <span className="checkInLocationDetailsLabel">Check-In Time</span>
          <div className="checkInLocationDetailsValue">
            {moment().format("h:mm A - MMMM DD, YYYY")}
          </div>
        </div>

        <div className="photoVerificationMainContainer">
          <div className="photoVerificationHeadingContainer">
            <div className="photoVerificationHeadingLeftContainer">
              <span className="photoVerificationHeading">
                Photo Verification
              </span>
              <span className="photoVerificationSubHeading">
                Take A Photo For Verification
              </span>
            </div>
            <div className="photoVerificationHeadingRightContainer">
              <CameraAltIcon sx={{ color: "#fff", fontSize: "16px" }} />
            </div>
          </div>
          {!photo ? (
            <div className="photoVerificationCaptureContainer">
              <input
                type="file"
                accept="image/*"
                capture="camera"
                onChange={handleCapture}
                style={{ display: "none" }}
                id="cameraInput"
              />
              <label htmlFor="cameraInput">
                <Button
                  className="photoVerificationCaptureBtn"
                  component="span"
                >
                  Take Photo
                </Button>
              </label>
            </div>
          ) : (
            <div className="photoPreviewContainer">
              <img
                src={photo.url}
                alt="Captured"
                className="photoPreviewImage"
              />
              <label htmlFor="cameraInput">
                <Button
                  className="retakePhotoBtn"
                  onClick={() => setPhoto(null)}
                >
                  Retake Photo
                </Button>
              </label>
            </div>
          )}
        </div>

        <Button className="checkInBtn" onClick={handleCheckIn}>
          {isCheckOut ? "Check-Out" : "Check-in"}
        </Button>

        {hospitalData?.lastCheckInTime ? (
          <div className="lastCheckInContainer">
            <div className="lastCheckInHeadingContainer">
              <CheckCircleIcon sx={{ color: "#22C55E", fontSize: "20px" }} />
              <span className="lastCheckInHeading">Last Check-in</span>
            </div>
            <div className="lastCheckInDetailsContainer">
              <p className="lastCheckInDetails">
                {formatDateTime(hospitalData?.lastCheckInTime)}
              </p>
              <p className="lastCheckInDetails">
                {hospitalData?.lastCheckInHospitalId
                  ? `${hospitalData.lastCheckInHospitalId}, ${
                      hospitalData.lastCheckInHospitalAddress || ""
                    }`
                  : hospitalData?.lastCheckInHospitalAddress || "NA"}
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              maxWidth: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              height: "max-content",
              paddingTop: "20px",
              paddingBottom: "30px",
              borderRadius: "10px",
              left: "50%",
              transform:
                window.innerWidth <= 500
                  ? "translate(-50%)"
                  : "translate(-50%, -50%)",
              bottom: window.innerWidth <= 500 ? 0 : "50%",
              top: window.innerWidth > 500 ? "50%" : "auto",
              borderBottomLeftRadius: window.innerWidth <= 500 ? 0 : "10px",
              borderBottomRightRadius: window.innerWidth <= 500 ? 0 : "10px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <span className="outOfRangeHeading">Out of Range</span>
            <span className="outOfRangeSubHeading">
              You are{" "}
              {Math.floor(hospitalData?.hospitalData?.distance || 10) +
                " " +
                (hospitalData?.hospitalData?.metrics || "Meter")}{" "}
              away from nearest
              {" " + hospitalData?.hospitalData?.hospitalName ||
                "Pristyn Hospital"}
              .
            </span>
            <span className="outOfRangeSubText">
              (You should be within{" "}
              {hospitalData?.hospitalData?.allowedDistance || 300}m from
              hospital)
            </span>

            {location.lat && location.lng ? (
              // <a
              //   // href={`https://www.google.com/maps?q=${location.lat},${location.lng}&z=18`}
              //   href={`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`}
              //   target="_blank"
              //   rel="noopener noreferrer"
              //   height="173px"
              // >
              //   <iframe
              //     title="User Location"
              //     width="100%"
              //     className="checkInMainMap"
              //     height="170px"
              //     style={{
              //       border: "none",
              //       pointerEvents: "none",
              //     }}
              //     src={`https://www.google.com/maps/embed/v1/place?key=${mapKey}&q=${location.lat},${location.lng}`}
              //     allowFullScreen
              //   ></iframe>
              // </a>
              <GoogleMapComponent
                mapHeight="170px"
                userLat={location.lat}
                userLng={location.lng}
              />
            ) : (
              <p className="loadingText">
                {!isRefresh ? error : "Fetching location..."}
              </p>
            )}
            <div className="outOfRangeAddressContainer">
              <LocationOnIcon sx={{ color: "#F74A56" }} />
              <span className="outOfRangeAddress">
                <strong>Nearest Hospital: </strong>
                {hospitalData?.hospitalData?.hospitalName &&
                hospitalData?.hospitalData?.hospitalAddress
                  ? `${hospitalData.hospitalData.hospitalName}, ${hospitalData.hospitalData.hospitalAddress}`
                  : hospitalData?.hospitalData?.hospitalName ||
                    hospitalData?.hospitalData?.hospitalAddress ||
                    "N/A"}
              </span>
            </div>
            <Button
              variant="contained"
              sx={{
                width: "90%",
                margin: "auto",
                fontSize: "16px",
                fontWeight: 600,
                borderRadius: "10px",
                height: "44px",
                marginTop: "5px",
              }}
              onClick={handleTryAgainModal}
            >
              Try Again
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default CheckInHome;
